import { Location } from './infoStructures';

export const MEDIASTUDIOS: Location[] = [ /** location list speially for Smith Media Studios accordion content*/
    {key: "assets/images/10-SMS/10_smith media studios-01.png",
    name: "Digital Media Hub",
    nameKey: "",
    resources: ["iMacs and PCs with full Adobe Creative Cloud & GIS Software suite", "012A Self Service Studio with Audio Recording Setup for voice over or podcasting", "012A Also includes studio lighting, black backdrop and green screen for video recording", "Access and reservations for use of studio spaces is the same as for equipment (see above)"],
    filtResources: ["Software Support", "Computers", "Printing"],
    hourNotes:"Open during Library Hours (varies)",
    hours: [],
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    accessNotes: "Assistance would be needed for opening studio doors, which is available at the Central Service Point desk. **** NOTE: 012B will be an advanced assisted studio, however it is not currently online yet.",
    access: true,
    location: "Neilson Library Level G",
    accessibility: "",
    image: "assets/images/10-Smith Media Studios/SmithMediaStudios-3.jpg",
    contact:[{name:"Dan Bennett", email:" dbennett@smith.edu"}],
    dot:"assets/images/10-Smith Media Studios/10-01.png",
    },

    {key: "assets/images/10-SMS/10_smith media studios-01.png",
    name: "Equipment Loan",
    nameKey: "",
    resources: ["Canon Vixia G20 Camcorders", "Canon 80D DSLRs", "Rode VideoMics", "LED light boxes","Zoom H4N and H0 Portable Audio Recorders", "18'' Ring Lights",
    "Tripods","Azden Wireless Lav Mics","Audio Technica AT2020 USB Condenser Mics","Logitech Brio and C390 Webcam Kits","Phone video kits with tripod mount and USB Lav Mic (for use with USB-A or USB-C devices)"],
    filtResources: ["Software Support", "Computers"],
    hourNotes:"Open during Library Hours (varies)",
    hours: [],
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    accessNotes: "Equipment is available on a walk-in basis, but reservations are recommended. Reservations can be made in-person at the CSP desk, or by using the Request Help link on the Smith Digital Support Site.",
    access: true,
    location: "Neilson Central Service Point",
    accessibility: "",
    image: "assets/images/10-Smith Media Studios/SmithMediaStudios-2.jpg",
    contact:[{name:"Dan Bennett", email:" dbennett@smith.edu"}],
    dot:"assets/images/10-Smith Media Studios/10-01.png",
    },

    {key: "assets/images/10-SMS/10_smith media studios-01.png",
    name: "Knowledge Lab",
    nameKey: "",
    resources: ["27 iMacs with secondary monitors and full Adobe Creative Cloud"],
    filtResources: ["Computers", "Printing", "Whiteboard"],
    hourNotes:"Open during Library Hours (varies)",
    hours: [],
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    accessNotes: "1st floor spaces are accessible",
    access: true,
    location: "Alumnae Gymnasium 202",
    accessibility: "",
    image: "assets/images/10-Smith Media Studios/SmithMediaStudios-4.jpg",
    contact:[{name:"Dan Bennett", email:" dbennett@smith.edu"}],
    dot:"assets/images/10-Smith Media Studios/10-01.png",
    },
    
]