import { Location } from './infoStructures';

export const LOCATIONS: Location[] = [
  
  {key: "assets/images/1-CC/conway_center.png", /** This is the location of the image in the key */
    name: "Conway Center", /** This is the name of the location */
    nameKey:"CC", 
    resources: ["Prototyping supplies", "Poster-making supplies", "Arts & crafts supplies"], /** Details on resources to go in popup window */
    filtResources: ["Arts and crafts supplies"], /** Resources by filter type */
    hourNotes: "Open and accessible when the library is open, swipe card access may be required. Hours subject to change during breaks.", /** Any specific notes on availability (staffed hours/classes/etc) */
    hours: [], /** Hours shown on the popup */
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"], /** Hours in 24 hour time used to filter */
    accessNotes: "Available when Neilson Library is open", /** Notes on access */
    access: true, /** All campus access true/false */
    location: "Inside Neilson Library", /** Location */
    accessibility: "Wheelchair accessible",
    image: "assets/images/1-CC/picture.jpg", /** Location of picture of space */
    contact:[{name:"CIEC", email:"ciec@smith.edu "}], /** Contact name and email */
    dot:"assets/images/1-CC/1-01.png", /** Location of dot image */
    },

    { key: "assets/images/2-Spatial Analysis Lab/2_SAL-01.png",
    name: "Spatial Analysis Lab",
    nameKey:"SAL",
    resources: ["20 Windows computers with standard Science Center software profile", "GIS, Remote Sensing, Photogrammetry applications."],
    filtResources: ["Software Support", "Computers", "Whiteboard"],
    hourNotes: "Not available during classes or workshops or meetings.  See Spatial Analysis Lab calendar for staffed hours.",
    hours: ["8:00AM-10:00PM","7:00AM - 11:00PM","7:00AM - 11:00PM","7:00AM - 11:00PM","7:00AM - 11:00PM","7:00AM - 11:00PM"
  ,"8:00AM-10:00PM",],
    filtHours:["8:00-22:00","7:00-23:00","7:00-23:00","7:00-23:00","7:00-23:00","7:00-23:00","8:00-22:00"],
    accessNotes: "Priority given to students enrolled in classes using the SAL.  Not available during class use.  Project system for instructor use only.",
    access: true,
    location: "Sabin-Reed 104",
    accessibility: "Wheelchair accessible",
    image: "assets/images/2-Spatial Analysis Lab/picture.jpeg",
    contact:[{name:"Jon Caris", email:"jcaris@smith.edu"}],
    dot:"assets/images/2-Spatial Analysis Lab/2-01.png",
    },

    {key: "assets/images/3-CDF/3_CDF-01.png",
    name: "The Center for Design and Fabrication",
    nameKey:"CDF",
    resources: ["Machine shop tools including Lathes, Mills and CNC router.", "Laser cutting and engraving.", "Four 3D printers.", "Wood working tools, saws, sanders, etc.", "Computer Aided Design teaching and support."],
    filtResources: ["Lasercutter", "3D printer", "Other fabrication tools", "Woodworking", "Computers", "Printing", "Whiteboard" ],
    hourNotes: "Scheduled appointments take precedence over drop in, though dropins are welcome!",
    hours: ["Closed", "8:30AM - 4:30PM","8:30AM - 4:30PM","8:30AM - 4:30PM","8:30AM - 4:30PM","8:30AM - 4:30PM","Closed"],
    filtHours:["24:00-0:00","8:30-16:30","8:30-16:30","8:30-16:30","8:30-16:30","8:30-16:30","24:00-0:00"],
    access: true,
    accessNotes: "Schedule an Appointment at https://tinyurl.com/y49evypk",
    location: "McConnell B07/B09",
    accessibility: "Wheelchair accessible. Elevator.",
    image: "assets/images/3-CDF/picture.jpeg",
    contact:[{name:"Eric Jensen", email:"ejensen@smith.edu"},{name: "Dale Renfrow",email:"drenfrow@smith.edu"}],
    dot:"assets/images/3-CDF/3-01.png",
    },

    {key: "assets/images/4-DTI/4_DTI-01.png",
    name: "Design Thinking Initiative Studios",
    nameKey:"DTIS",
    resources: ["Vinyl cutter", "3D printer", "Laser cutter", "Craft supplies", "Sewing machine and hand tools!"],
    filtResources: ["Lasercutter","3D printer","Other fabrication tools", "Arts and crafts supplies",
  "Computers", "Whiteboard"],
    hourNotes: "The prototyping studio is open during 9am to 5pm on weekdays when the college is open, and during student staffed Open Hours: https://www.smith.edu/academics/design-thinking/studio#hours",
    hours: ["Closed", "9:00AM - 5:00PM","9:00AM - 5:00PM","9:00AM - 5:00PM","9:00AM - 5:00PM","9:00AM - 5:00PM", "Closed"],
    filtHours:["24:00-0:00","9:00-17:00","9:00-17:00","9:00-17:00","9:00-17:00","9:00-17:00","24:00-0:00"],
    access: true,
    accessNotes: "You may gain OneCard access to Design Thinking Initiative from 6 am to 1 am after completing our Safety Training Quiz: https://www.smith.edu/academics/design-thinking/studio/safety-training",
    location: "Capen Annex Room 203",
    accessibility: "While the Downstairs prototyping studio is wheelchair accessible, the upstairs prototyping studio is not. Please contact us if you need to arrange for appropriate accommodations.",
    image: "assets/images/4-DTI/picture.jpeg",
    contact:[{name: "Capen Annex",email:"capenannex@smith.edu"}],
    dot:"assets/images/4-DTI/4-01.png",
    },

    {key: "assets/images/5-JMM/5_creative_co_op-01.png",
    name: "Creative Co-op at Jandon",
    nameKey:"CCJC",
    resources: ["Support but not limited to our Crafting for Community STEAM Outreach Initiative"],
    filtResources: ["Arts and craft supplies"],
    hourNotes:"Hours subject to change during breaks and over the summer.",
    hours: ["Closed", "4:00PM - 6:00PM","Closed","Closed","Closed","1:00PM - 3:00PM", "Closed"],
    filtHours:["24:00-0:00","16:00-18:00","24:00-0:00","24:00-0:00","24:00-0:00","13:00-15:00","24:00-0:00"],
    access: true,
    accessNotes: "Special activities, workshops and requests may fall outside regular hours",
    location: "Wright Hall Room 13",
    accessibility: "1st Floor is wheelchair accessible",
    image: "assets/images/5-JMM/Jandon_1.jpg",
    contact:[{name:"Deborah Day",email:"dday@smith.edu"}],
    dot:"assets/images/5-JMM/5-01.png",
    },

    {key: "assets/images/6-Resource Room/6_resourcerm-01.png",
    name: "The Resource Room",
    nameKey:"RR",
    resources: ["Poster-making supplies", "Photocopier", "Markerpaints", "Arts & crafts supplies"],
    filtResources: ["Arts and craft supplies","Whiteboard"],
    hourNotes:"Hours subject to change during breaks",
    hours: ["9:00AM - 11:30PM", "7:30AM - 11:30PM","7:30AM - 11:30PM","7:30AM - 11:30PM","7:30AM - 11:30PM","7:30AM - 11:30PM", "9:30AM - 11:30PM"],
    filtHours:["9:00-23:30","7:30-23:30","7:30-23:30","7:30-23:30","7:30-23:30","7:30-23:30","9:30-23:30"],
    access: true,
    accessNotes: "Available when Campus Center is open",
    location: "Campus Center Room 203",
    accessibility: "Wheelchair accessible via elevator",
    image: "assets/images/6-Resource Room/picture.jpeg",
    contact:[{name:"OSE",email:"ose@smith.edu"}],
    dot:"assets/images/6-Resource Room/6-01.png",
    },

    {key: "assets/images/7-Imaging Center/7_imagingctr-01.png",
    name: "The Imaging Center",
    nameKey:"TIC",
    resources: ["3D printers", "Large paper cutter", "Adobe Suite and 3D editing software", "iMac computers", "Large format printers", "Contact staff for 3D scanning and poster printing support."],
    filtResources: ["3D printer", "Software support", "Computers", "Printing", "Whiteboard"],
    hourNotes:"No use during lectures",
    hours: ["Closed","8:30AM -4:30PM","8:30AM - 4:30PM","8:30AM - 4:30PM","8:30AM - 4:30PM","8:30AM - 4:30PM","Closed"],
    filtHours:["24:00-0:00","8:30-16:30","8:30-16:30","8:30-16:30","8:30-16:30","8:30-16:30","24:00-0:00"],
    access: true,
    accessNotes: "Open to students enrolled in Art Department courses.",
    location: "Hillyer H324",
    accessibility: "Wheelchair accessible",
    image: "assets/images/7-Imaging Center/imaging center photo.jpg",
    contact:[{name: "Jon Cartledge", email:"jcartled@smith.edu"}],
    dot:"assets/images/7-Imaging Center/7-01.png",
    },

    {key: "assets/images/8-Gaming Lab/8_gaminglab-01.png",
    name: "The Gaming Lab",
    nameKey:"TGL",
    resources: ["Gaming equipment includes:", "3 gaming PCs", "Arcade cabinet", "HTC Vive", "Oculus Rift", "Playstation VR", "2 PS4s", "Xbox One", "PS3", "XBox 360", "Nintendo Switch", "Game library"],
    filtResources: ["Computers"],
    hourNotes:"Occasionally reserved for class projects",
    hours: [],
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    access: true,
    accessNotes: "Check the Facebook page for current semester hours: https://www.facebook.com/gaminglabatsmithcollege/",
    location: "Hillyer H326",
    accessibility: "Wheelchair accessible via elevator",
    image: "assets/images/8-Gaming Lab/picture.jpeg",
    contact:[{name: "Andrew Maurer",email:"ajmaurer@smith.edu"}],
    dot:"assets/images/8-Gaming Lab/8-01.png",
    },

    {key: "assets/images/9-Hilyer Woodshop/9_hwoodshop-01.png",
    name: "The Hillyer Woodshop",
    nameKey:"THW",
    resources: ["Woodworking"],
    filtResources: ["Woodworking"],
    hourNotes:"When Drew is available",
    hours: ["Closed", "Closed","8:00AM - 5:00PM","8:00AM - 5:00PM","Closed","8:00AM - 5:00PM","Closed"],
    filtHours:["24:00-0:00","24:00-0:00","8:00-17:00","8:00-17:00","24:00-0:00","8:00-17:00","24:00-0:00"],
    access: true,
    accessNotes: "Available to drop-in when Drew Palmore is in the Woodshop",
    location: "Hillyer Basement",
    accessibility: "Wheelchair accessible",
    image: "assets/images/9-Hilyer Woodshop/Woodshop_1.jpg",
    contact:[{name:"Drew Palmore", email:"apalmore@smith.edu"}],
    dot:"assets/images/9-Hilyer Woodshop/9-01.png",
    },

    {key: "assets/images/10-Smith Media Studios/10_smith media studios-01.png",
    name: "Smith Media Studios",
    nameKey:"SMS",
    resources: [],
    filtResources: ["Software Support", "Computers", "Printing", "Whiteboard"],
    hours: [],
    hourNotes:"Open during Library Hours (varies)",
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    access:  true,
    accessNotes: "",
    location: "",
    accessibility: "Wheelchair accessible",
    image: "assets/images/10-Smith Media Studios/SmithMediaStudios-1.jpg",
    contact:[{name:"Dan Bennett", email:" dbennett@smith.edu"}],
    dot:"assets/images/10-Smith Media Studios/10-01.png",
    },

    {key: "assets/images/11-EGR Studio/11_egr studio-01.png",
    name: "Engineering Studio",
    nameKey:"ES",
    resources: ["Hand tools", "Electronics", "Craft & proto materials", "Floor drain"],
    filtResources: ["Arts and crafts supplies", "Computers", "Whiteboard"],
    hourNotes:"No use during lectures",
    hours: [],
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    access: false,
    accessNotes: "24/7 swipe for EGR students, others by request; staff support by request",
    location: "Burton B016, B017",
    accessibility: "Wheelchair accessible via Sabin/Burton elevator; power assist door B016",
    image: "assets/images/11-EGR Studio/picture.jpeg",
    contact:[{name:"Sue Froehlich", email:"sfroehli@smith.edu"}],
    dot:"assets/images/11-EGR Studio/11-01.png",
    },

    {key: "assets/images/12-Mechanics Playground/12_mechanics playground-01.png",
    name: "Mechanics Playground",
    nameKey:"MP",
    resources: ["Hand tools", "Concrete station", "Sewing machine", "Craft & proto materials", "Electronics", "Soldering", "Basic lab equipment", "Overhead lift points", "Unistrut wall; overhead water", "Air & exhaust", "Bike tools", "Ladders", "Lasercutter", "3D printer in FH002",  "Tornado machine!"],
    filtResources: ["Lasercutter", "3D printer","Other fabrication tools","Arts and craft supplies", "Software support", "Computers", "Printing", "Whiteboard"],
    hourNotes:"Scheduled lecture/lab needs take priority, but 022 workspace available during lectures in 024.",
    hours: [],
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    access: false,
    accessNotes: "24/7 swipe for EGR students, others by request; staff support weekdays",
    location: "Ford Hall LL 022, 024, 002",
    accessibility: "Accessible for humans & most projects via exterior 1.5-door @ grade level; 'forklift accessible' but no cars",
    image: "assets/images/12-Mechanics Playground/picture.jpeg",
    contact:[{name:"Sue Froehlich", email:"sfroehli@smith.edu"}],
    dot:"assets/images/12-Mechanics Playground/12-01.png",
    },
    
    {key: "assets/images/13-Theater Scene Shop/13_theater_scene-01.png",
    name: "Theatre Scene Shop",
    nameKey:"TSS",
    resources: ["Wood-shop and scene-shop tools", "Painting supplies", "Cloth-dying", "Hand tools", "Table saw", "Drill press", "Spray booth", "Upholstery equipment and supplies", "etc"],
    filtResources: ["Woodworking"],
    hourNotes:"Accesssible per request",
    hours: ["Closed", "10:00AM - 5:00PM","10:00AM - 5:00PM","10:00AM - 5:00PM","10:00AM - 5:00PM","10:00AM - 5:00PM","Closed"],
    filtHours:["24:00-0:00","10:00-17:00","10:00-17:00","10:00-17:00","10:00-17:00","10:00-17:00","24:00-0:00"],
    access: false,
    accessNotes: "Available by appointment.",
    location: "Mendenhall [behind the stage]",
    accessibility: "Wheelchair accessible",
    image: "assets/images/13-Theater Scene Shop/theater set shop photo.JPG",
    contact:[{name:"Daniel D. Rist", email:"drist@smith.edu"},{name:"Alan Schneider",email:"awschneider@smith.edu"}],
    dot:"assets/images/13-Theater Scene Shop/13-01.png",
    },

    {key: "assets/images/14-Costume Shop/14_costume shop-01.png",
    name: "The Costume Shop",
    nameKey:"TCS",
    resources: ["Sewing machines", "Dress forms", "Iron and steaming", "Flat patterning tools"],
    filtResources: ["Other fabrication tools","Arts and craft supplies","Computers","Printing"],
    hours: ["Closed", "9:00AM - 5:00PM","9:00AM - 5:00PM","9:00AM - 5:00PM","9:00AM - 5:00PM","9:00AM - 5:00PM", "Closed"],
    hourNotes:"Closed for lunch from 12-1",
    filtHours:["24:00-0:00","9:00-17:00","9:00-17:00","9:00-17:00","9:00-17:00","9:00-17:00","24:00-0:00"],
    accessNotes: "Limited to students enrolled in Costume Classes",
    access: false,
    location: "Mendenhall Room T12",
    accessibility: "Wheelchair accessible via elevator",
    image: "assets/images/14-Costume Shop/CostumeShop_2.jpg",
    contact:[{name:"Emily Dunn", email:"edunn2@smith.edu"}],
    dot:"assets/images/14-Costume Shop/14-01.png",
    },
    
    {key: "assets/images/15-Hillyer Art Studios/15_hilyer studios-01.png",
    name: "Hillyer Art Studios",
    nameKey:"HAS",
    resources: ["Discipline specific studios including:", "Darkrooms", "Digital labs", "Print studio","Sculpture studio","Typography studio"],
    filtResources: ["Other fabrication tools","Arts and craft supplies"],
    hours: [],
    hourNotes:"24/7 access for students enrolled in classes.",
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    access: false,
    accessNotes: "Open to students in art classes using studio space only.",
    location: "Hillyer Art Building",
    accessibility: "Wheelchair accessible",
    image: "assets/images/15-Hillyer Art Studios/HilyerClassroom_1.jpg",
    contact:[{name:"Lindsey Clark-Ryan", email:"lclarkry@smith.edu"}],
    dot:"assets/images/15-Hillyer Art Studios/15-01.png",
    },

    {key: "assets/images/16-Landscape Studies Studio/16_lss-01.png",
    name: "Landscape Studies Studio",
    nameKey:"LSS",
    resources: ["10 Windows machines", "Spiral binding machine", "Large format printer", "Low fidelity model building tools and supplies", "Tracing paper", "Milk crates"],
    filtResources: ["Other fabrication tools", "Arts and craft supplies", "Software support", "Computers", "Printing", "Whiteboard"],
    hourNotes:"OneCard Accessible 24/7",
    hours: [],
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    access: false,
    accessNotes: "Access to space is only upon request",
    location: "Burton Room 406",
    accessibility: "Wheelchair accessible via Sabin-Burton elevator",
    image: "assets/images/16-Landscape Studies Studio/picture.jpeg",
    contact:[{name:"Reid Bertone-Johnson",email:"rbertone@smith.edu"}],
    dot:"assets/images/16-Landscape Studies Studio/16-01.png",
    },
  
    {key: "assets/images/17-Digital Music Lab/17_digital music lab-01.png",
    name: "Digital Music Lab",
    nameKey:"DML",
    resources: ["13 workstations with MIDI keyboards", "Computers whose software includes Reaper and Audacity (digital audio workstations)", "Sibelius and Musescore (music notation software)", "Max/MSP"],
    filtResources: ["Software Support", "Computers"],
    hours: [],
    hourNotes:"Anytime with card swipe access (see below)",
    filtHours:["0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00","0:00-24:00"],
    access:  true,
    accessNotes: "Students enrolled in a music course that uses the DML, or any student (or other) who writes to me and Anna to gain access.",
    location: "Sage Hall room 315",
    accessibility: "Accessible via elevator to the 3rd floor.",
    image: "assets/images/17-Digital Music Lab/DigitalMusicLab-1.jpg",
    contact:[{name:"Anna Goudreau", email:"agoudreau@smith.edu"}, {name:"Kate Soper", email:"ksoper@smith.edu"}],
    dot:"assets/images/17-Digital Music Lab/17-01.png",
    }
    
];